
    import { mapState, mapMutations, mapActions } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faTimes as farTimes,
    } from '@fortawesome/pro-regular-svg-icons/faTimes';

    export default {
        name: 'MobileMenu',

        components: {
            FontAwesomeIcon,
            PoweredByC1: () => import(/* webpackChunkName: "FeedbackModal" */'@/components/Partials/PoweredByC1'),
        },

        data() {
            return {
                mobileMenuOverlay: '',
                icons: {
                    farTimes,
                },
            };
        },

        computed: {
            ...mapState({
                $_isMobileMenuOpen: state => state.appInfo.isMobileMenuOpen,
                $_me: state => state.me.user,
            }),
        },

        watch: {
            $_isMobileMenuOpen(value) {
                this.animateMobileMenu(value);
            },
            mobileMenuOverlay(o) {
                if (o) {
                    o.onclick = () => {
                        this.$_closeMobileMenu();
                    };
                }
            },
        },

        beforeDestroy() {
            this.$_closeMobileMenu();
            const o = document.getElementById('mobileMenuOverlay');
            if (o) {
                o.remove();
            }
        },

        methods: {
            ...mapMutations('appInfo', {
                $_closeMobileMenu: 'CLOSE_MOBILE_MENU',
            }),

            ...mapActions('me', {
                $_pushToOnboarding: 'pushToOnboarding',
            }),

            animateMobileMenu(isOpen) {
                const opt = {
                    translateX: [0, '-100%'],
                };

                if (!isOpen) {
                    opt.translateX = ['-100%', 0];

                    const o = document.getElementById('mobileMenuOverlay');
                    this.$velocity(o, 'fadeOut', {
                        easing: 'easeInOutQuart',
                        duration: 700,
                        complete: () => {
                            o.remove();
                            this.mobileMenuOverlay = '';
                        },
                    });
                } else {
                    const overlay = document.createElement('div');
                    overlay.id = 'mobileMenuOverlay';
                    overlay.style.position = 'fixed';
                    overlay.style.top = '0';
                    overlay.style.left = '0';
                    overlay.style.width = '100%';
                    overlay.style.height = '100%';
                    overlay.style.backgroundColor = 'rgba(0, 0, 0, .3)';
                    overlay.style.zIndex = '99999';
                    const body = document.getElementsByTagName('body')[0];
                    body.appendChild(overlay);
                    this.$velocity(overlay, 'fadeIn', { easing: 'easeInOutQuart', duration: 700 });
                    this.mobileMenuOverlay = document.getElementById('mobileMenuOverlay');
                }

                const vm = this;
                this.$velocity(this.$el, opt, {
                    easing: 'easeInOutQuart',
                    duration: 700,
                    begin() {
                        if (isOpen) {
                            vm.$el.classList.add('d-block');
                        }
                    },
                    complete() {
                        if (!isOpen) {
                            vm.$el.classList.remove('d-block');
                        }
                    },
                });

                if (window.innerWidth >= 768) {
                    this.$el.style.width = '320px';
                } else {
                    this.$el.style.width = '100vw';
                }
            },

            onWindowResize() {
                if (window.innerWidth >= 1200) {
                    this.$_closeMobileMenu();
                } else if (window.innerWidth >= 768) {
                    this.$el.style.width = '320px';
                } else {
                    this.$el.style.width = '100vw';
                }
            },
        },
    };
